import _ from 'lodash'
export default filters =>
  filters.reduce((acc, filter) => {
    const existingIndex = acc.findIndex(x => x[0] === filter[0])
    if (existingIndex > -1) {
      return [
        ...acc.slice(0, existingIndex),
        _.uniq(acc[existingIndex].concat(filter.slice(1))),
        ...acc.slice(existingIndex + 1),
      ]
    } else {
      return [...acc, filter]
    }
  }, [])
