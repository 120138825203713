import React from 'react'

import './UEHeader.sass'

export default () => (
  <nav className="level ue-header is-marginless">
    <div className="level-left">
      <div className="level-item">
        <p className="title is-5 has-text-white ue-header-title">University Explorer</p>
      </div>
    </div>
  </nav>
)
