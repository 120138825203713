import React, { useState } from 'react'

import Table from './Table'

import SummaryData from './SummaryData'
import TopBar from './TopBar'

export default function UniversityExplorer(props) {
  /*************************** lifecycle methods ***************************/
  const [cipCodes, setCipCodes] = useState([])

  const { attachSchoolInfoToUnitIDs, filters } = props
  return (
    <div className="mainContent">
      <TopBar cipCodes={cipCodes} setCipCodes={setCipCodes} wayup={props.wayup || false} />
      <SummaryData
        attachSchoolInfoToUnitIDs={attachSchoolInfoToUnitIDs}
        cipCodes={cipCodes}
        filters={filters}
        wayup={props.wayup || false}
      />
      <Table
        attachSchoolInfoToUnitIDs={attachSchoolInfoToUnitIDs}
        cipCodes={cipCodes}
        filters={filters}
        wayup={props.wayup || false}
      />
    </div>
  )
}

// const mapStateToProps = state => ({
//   trending: [], // state.reports.trending,
//   new: [], // state.reports.new,
//   // searchQuery: state.searchbar.searchQuery
// });
//
// export default connect(
//   mapStateToProps,
//   {}
// )(UniversityExplorer);
