import React, { memo } from 'react'
import _ from 'lodash'
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps'
import interpolate from 'color-interpolate'

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

// NOTE(Robin): To start at a specific place, we just need to pass the defaults
// center: coordinates
// zoom: 40 (play around with this)

// Note: Chart expects data in increasing order (it will write the largest values last)
const MapChart = ({ setTooltipContent, data, wayup }) => {
  const colormap = wayup ? interpolate(['#f9f9f9', '#16D8ED']) : interpolate(['#f9f9f9', '#4ec6ab'])

  const fill = value => colormap((Math.log10(value || 0.01) + 2) / 2)

  const min = data.length ? data[0].CTOTALT : 0
  const max = data.length ? data[data.length - 1].CTOTALT : 0
  const fillPercentage = value => (value - min) / max
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
      <div style={{ flex: 1, width: '100%' }}>
        <ComposableMap projection="geoAlbersUsa" data-tip="">
          <Geographies geography={geoUrl}>
            {({ geographies }) => (
              <>
                {geographies.map(geo => (
                  <Geography
                    key={geo.rsmKey}
                    stroke={wayup ? '#b0f7ff' : '#46615a'}
                    geography={geo}
                    fill="#4c4c4c"
                  />
                ))}
              </>
            )}
          </Geographies>
          {data
            .filter(uni => uni.coordinates && !_.isNil(uni.coordinates.latitude))
            .map(({ unitid, institutionName, CTOTALT, coordinates: { latitude, longitude } }) => {
              return (
                <Marker key={unitid} coordinates={[longitude, latitude]}>
                  <circle
                    r={5}
                    fill={fill(fillPercentage(CTOTALT))}
                    onMouseEnter={() => {
                      setTooltipContent(`${institutionName} — ${CTOTALT}`)
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('')
                    }}
                  />
                </Marker>
              )
            })}
        </ComposableMap>
      </div>
      <div className="map-legend">
        {[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map(step => (
          <div className="map-legend-item" key={step}>
            <div
              style={{ background: fill(step) }}
              className={`map-legend-color ${step === 0 ? 'rounded-left' : ''} ${
                step === 1 ? 'rounded-right' : ''
              }`}
            />
            <strong className="is-size-7">
              {Math.round(max * step) > 1000
                ? `${Math.round(max * step)
                    .toString()
                    .slice(0, -3)}.${Math.round(max * step)
                    .toString()
                    .slice(-3, -2)}k`
                : Math.round((max * step) / 100) * 100}
            </strong>
          </div>
        ))}
      </div>
      <strong className="is-size-7">Total Graduates (2020)</strong>
    </div>
  )
}

export default memo(MapChart)
